import en from 'vuetify/lib/locale/en'

export default {
  account: 'account',
  accountNotActive: 'account is disabled',
  accounts: 'accounts',
  active: 'active',
  add: 'add',
  addAccount: 'add account',
  address: 'address',
  addSelected: 'add selected',
  additionalLanguage: 'additional language',
  administration: 'administration',
  all: 'all',
  allWeek: 'all week',
  any: 'any',
  apiDocs: 'API docs',
  apiKeys: 'API keys',
  apply: 'apply',
  back: 'back',
  backToLoginPage: 'back to login page',
  beverage: 'beverage',
  beverages: 'beverages',
  brand: 'brand',
  brands: 'brands',
  businessHours: 'business hours',
  byDays: 'by days',
  cancel: 'cancel',
  cancelDelete: 'cancel delete?',
  canceled: 'canceled',
  cantDeleteFirstDeleteRelations:
    'deletion is not allowed, delete related objects first',
  cantDeleteDefaultValue: 'can`t delete default value',
  cause: 'cause',
  causeFaultGroup: 'group causes of faults',
  causeFaultGroups: 'groups causes of faults',
  causeFaults: 'causes of faults',
  causeGroups: 'cause groups',
  causes: 'causes',
  changePassword: 'change password',
  cities: 'cities',
  city: 'city',
  client: 'client',
  clone: 'clone',
  clientFault: 'call reasons',
  clientFaultGroup: 'group of call reasons',
  clientFaultGroups: 'groups of call reasons',
  clientFaults: 'call reasons',
  clients: 'clients',
  close: 'close',
  closed: 'closed',
  code: 'code',
  color: 'color',
  comment: 'comment',
  completedDate: 'completion date',
  connectionLost: 'connection lost',
  connectionRestored: 'connection restored',
  confirmDelete: 'are you sure you want to delete this item?',
  confirmOrderCancel:
    'are you sure you want to cancel this order? This action cannot be undone',
  confirmPassword: 'confirm password',
  connectServerError: 'error connect to server',
  contactPerson: 'contact person',
  contactPersons: 'contact persons',
  contract: 'contract',
  contractor: 'contractor',
  contracts: 'contracts',
  controlTechConditions: 'control of tech. conditions',
  counter: 'counter',
  counterFrom: 'counter from',
  counterTo: 'counter to',
  countries: 'countries',
  country: 'country',
  create: 'create',
  createdBasedOrder: 'created based on the order',
  createdDate: 'created date',
  createdOrder: 'new order has been created',
  date: 'date',
  dateEnd: 'date end',
  dateStart: 'date start',
  days: 'days',
  daysDot: 'd.',
  decisionMakerName: 'decision-maker name',
  decisionMakerPhone: 'decision-maker phone',
  default: 'default',
  defaultLocationBusinessHours: 'default location business hours',
  defaultPageTitle: 'CM-Service DESK',
  defaultReactionTime: 'default reaction time',
  defaultSolutionTime: 'default solution time',
  delete: 'delete',
  deleteAccount: 'delete account',
  deleteUserAccount:
    'the account will be deleted and the user will no longer be able to log in.',
  description: 'description',
  details: 'details',
  disableAllSorts: 'disable all sorts',
  distance: 'distance',
  distanceFromService: 'distance from SC',
  document: 'document',
  download: 'download',
  edit: 'edit',
  editDate: 'edit date',
  editingWillBeAwailableOn: 'editing will be available on',
  employee: 'employee',
  eqTypes: 'equipment types',
  equipment: 'equipment',
  equipments: 'equipments',
  error: 'error',
  errorCreatingRepeatedOrder: 'error creating repeated order',
  errorLoadingFromServer: 'error loading from server',
  every: 'every',
  fault: 'fault',
  faultCode: 'fault code',
  faultGroup: 'fault group',
  faultGroups: 'fault groups',
  faults: 'faults',
  filter: 'filter',
  filters: 'filters',
  firstDeleteAllRelations: 'first you need to remove all related elements',
  firstName: 'first-name',
  forgotPassword: 'forgot password?',
  from: 'from',
  fromClients: 'from clients',
  generalInfo: 'general info',
  globals: 'globals',
  gps: 'gps',
  group: 'group',
  groups: 'groups',
  hide: 'hide',
  home: 'home',
  hours: 'hours',
  hoursDot: 'h.',
  identFaultGroups: 'groups identificated faults',
  identFaults: 'identificated faults',
  idents: 'identificated',
  instruction: 'instruction',
  isRepeat: 'repeats',
  key: 'key',
  km: 'km',
  language: 'language',
  lastName: 'last-name',
  loading: 'loading',
  location: 'location',
  locations: 'locations',
  login: 'login',
  loginIncorect: 'wrong login or password',
  mailingList: 'email for mailing',
  managerEmail: 'manager Email',
  managerName: 'manager Name',
  managerPhone: 'manager Phone',
  maximum: 'maximum',
  maxReactionTimeByContract: 'max. reaction time by contract',
  maxSolutionTimeByContract: 'max. solution time by contract',
  maxTimeOfWorkByContract: 'max. time of work by contract',
  maxWorkTime: 'maximum work time',
  middleName: 'middle-name',
  minimum: 'minimum',
  minutes: 'minutes',
  minutesDot: 'min.',
  mode: 'mode',
  model: 'model',
  models: 'models',
  name: 'name',
  new: 'new',
  nextTry: 'next try',
  no: 'no',
  noData: 'no data',
  notActive: 'not active',
  notCanceled: 'not canceled',
  notFound: 'not found',
  notFoundDescription: 'the resource may have been moved or deleted',
  notReceiveCode: 'not receive code?',
  notRepeat: 'non-repetitive',
  notification: 'notification',
  notifications: 'notifications',
  notificationTimeTaked: 'time to notification about taked',
  notificationTimeReacted: 'time to notification about reacted',
  notificationTimeResolved: 'time to notification about resolved',
  now: 'now',
  number: 'number',
  object: 'object',
  objects: 'objects',
  onlyActive: 'only active',
  onlyByZone: 'only from zones',
  onlyTheir: 'only their',
  opened: 'opened',
  order: 'order',
  orderBusinessHours: 'order business hours',
  orderCancel: 'order canceled',
  orderCanceled: 'canceled',
  orderCreatorDetails: 'order details',
  orderCreatorName: 'order creator name',
  orderCreatorPhone: 'order creator phone',
  orderCreatorType: 'order creator type',
  orderEditRules: 'order edit rules',
  orders: 'orders',
  orderStatus: 'order status',
  orderStatuses: 'order statuses',
  orderStatusHistory: 'order statuses history',
  orderTriggerHint:
    'a new order will be created when the current one moves to this status',
  orderType: 'order type',
  orderTypes: 'order types',
  otherFault: 'other call reasons',
  otherM: 'other',
  others: 'others',
  otherW: 'other',
  owner: 'owner',
  owners: 'owners',
  part: 'part',
  partGroup: 'part group',
  partGroups: 'part groups',
  parts: 'parts',
  password: 'password',
  period: 'period',
  permissions: 'permissions',
  phone: 'phone',
  phones: 'phones',
  placement: 'placement',
  places: 'places',
  pressure: 'pressure',
  range: 'range',
  reaction: 'reaction',
  reactionDate: 'work start date',
  reactionTime: 'reaction time',
  reactionTimer: 'reaction timer',
  regions: 'regions',
  region: 'region',
  removeSelected: 'remove selected',
  repeat: 'repeat',
  repeatedOrder: 'repeated order',
  resend: 'resend',
  reset: 'reset',
  resetSort: 'reset sort',
  retry: 'retry',
  role: 'role',
  roles: 'roles',
  save: 'save',
  savePassword: 'save password',
  salesByBeverages: 'sales by beverages',
  salesByWeekDays: 'sales by week days',
  salesByHours: 'sales by hours',
  search: 'search',
  selectAll: 'select all',
  selectDaysOfTheWeek: 'select days of the week',
  sendCode: 'send code',
  serialNumber: 'serial number',
  serverError: 'server error',
  serverValidationError: 'validation error on server',
  serviceCenter: 'service center',
  settings: 'settings',
  scanQrForServiceRequest: 'Scan this QR to create a repair order',
  shortName: 'name',
  show: 'show',
  showAllColumns: 'show all columns',
  showLessColumns: 'hide columns',
  showMore: 'show more',
  sla: 'service level agreement',
  solution: 'solution',
  solutionDate: 'work finish date',
  solutionTime: 'solution time',
  solutionTimer: 'solution timer',
  sort: 'sort',
  sortDefault: 'default sort',
  staff: 'staff',
  staffs: 'staffs',
  state: 'state',
  status: 'status',
  statuses: 'statuses',
  stopNotifications: 'stop notifications',
  stopReactionTimer: 'stop reaction timer',
  stopSolutionTimer: 'stop solution timer',
  superAdmin: 'super-admin',
  tableWidth: 'table width',
  telegram: 'telegram',
  telemetry: 'telemetry',
  time: 'time',
  timeByFaultType: 'time by fault type',
  timeDefault: 'default time',
  timeOfWork: 'time of work',
  title: 'title',
  toRecoverPassword:
    'to recover your password, enter yor phone number and we will send you an SMS with recovery code',
  transitionFrom: 'transition from',
  transitionTo: 'transition to',
  trigger: 'trigger',
  to: 'to',
  total: 'total',
  type: 'type',
  types: 'types',
  unselectAll: 'unselect all',
  user: 'user',
  userName: 'username',
  users: 'users',
  verifyCode: 'verify code',
  video: 'video',
  voltage: 'voltage',
  waitLoadingFromServer: 'wait loading from server',
  weeks: 'weeks',
  withoutAccount: 'wihtout account',
  work: 'work',
  workGroups: 'work groups',
  works: 'works',
  workTime: 'time of work',
  workType: 'work type',
  workTypes: 'work types',
  yes: 'yes',
  zone: 'zone',
  zones: 'zones',
  validation: {
    alpha: 'should only contain letters',
    alreadyTaked: 'already taked',
    alreadyStaff: 'another contractor is already listed, reload the page',
    brand: {
      notActive: 'brand is not active'
    },
    client: {
      notActive: 'client is not active'
    },
    contract: {
      notActive: 'no active contract found',
      notActiveOrExpired: 'no active contract found or the contract has expired'
    },
    email: 'must be a valid email',
    emails: 'enter emails by coma',
    eqType: {
      notActive: 'equipment type is not active'
    },
    equipment: {
      notActive: 'equipment is not active'
    },
    integer: 'must be an integer',
    ipVsPort: 'mast be a valid ip and port: xxx.xxx.xxx.xxx:xxxxx',
    isChangedValue: 'select new value',
    length: 'must have {n} character | must have {n} characters',
    location: {
      notActive: 'location is not active'
    },
    maxLength: 'max {n} character | max {n} characters',
    maxValue: 'maximum value - {n}',
    minLength: 'min {n} character | min {n} characters',
    minValue: 'minimum value - {n}',
    model: {
      notActive: 'model is not active',
      noContract:
        'no active contract found for this equipment model or the contract term has expired'
    },
    numeric: 'must be a number',
    password: {
      hasLowerCase: 'lower case letter',
      hasNumbers: 'number',
      hasSymbol: 'symbol',
      hasUpperCase: 'upper case letter',
      minLength: 'min. 8 characters',
      mustContain: 'password must contain: '
    },
    passwordMismatch: 'password mismatch',
    phone: 'must be a valid phone: +XXXXXXXXXX',
    repeatedOrderDate:
      'attention! The date of the next order is greater than the expiration date of the contract for equipment maintenance, the order may not be created!',
    required: 'cannot be empty',
    requiredOneOf: 'at least one of the fields must be filled',
    sameAs: 'does not match: {n}',
    url: 'must be a valid url link'
  },
  weekDays: {
    sunday: 'sunday',
    monday: 'monday',
    tuesday: 'tuesuday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    holiday: 'holiday',
    sun: 'sun',
    mon: 'mon',
    tue: 'tue',
    wed: 'wed',
    thu: 'thu',
    fri: 'fri',
    sat: 'sat'
  },
  $vuetify: en
}
