export const objValueFromDotString = {
  methods: {
    objValueFromDotString(object, string) {
      const recursive = (obj, array) => {
        if (array.length === 1) {
          return obj[array[0]]
        }
        return recursive(obj[array[0]], array.slice(1))
      }
      return recursive(object, string.split('.'))
    }
  }
}

export const shortNameFromItem = {
  methods: {
    shortNameFromItem(item) {
      if (item) {
        const lastName = this.isGlobalLocale ? item.lastNameI18n : item.lastName

        const firstName = this.isGlobalLocale
          ? item.firstNameI18n
          : item.firstName

        const middleName = this.isGlobalLocale
          ? item.middleNameI18n
          : item.middleName

        return `${lastName} ${firstName[0]}.  ${
          middleName ? middleName[0] : ''
        }${middleName ? '.' : ''}`
      }
      return ''
    }
  }
}

export const getUniqueValues = {
  methods: {
    getUniqueValues(prop) {
      return [
        ...new Set(
          this.items
            .map((item) => {
              const propValue = prop
                .split('.')
                .reduce((obj, name) => obj && obj[name], item)
              return propValue
            })
            .filter((value) => !!value)
        )
      ]
    }
  }
}

export const filter = {
  methods: {
    filter(item, prop) {
      // console.log(this.value[prop], item.id)
      return (
        !this.value[prop] ||
        !this.value[prop].length ||
        this.value[prop].includes(item?.id)
      )
    }
  }
}

export const filterArray = {
  methods: {
    filterArray(arr, prop) {
      return (
        !this.value[prop] ||
        !this.value[prop].length ||
        arr.some((val) => this.value[prop].includes(val?.id))
      )
    }
  }
}

export const permissions = {
  methods: {
    isCan(permissions) {
      return (
        this.currentUser &&
        (!permissions?.length ||
          permissions.some((permission) => this.currentUser.role[permission]))
      )
    }
  }
}

export const getTranslation = {
  methods: {
    getTranslation(item) {
      return (
        (this.isGlobalLocale && item?.titleI18n
          ? item?.titleI18n
          : item?.title) || ''
      )
    }
  }
}
